<template>
<el-dialog v-model="visible" title="新增用户" :append-to-body="true" custom-class="authority-add-dialog" :close-on-click-modal="false">
  <el-form :model="form" v-loading="loading" ref="ruleFormRef" :rules="rules" label-width="80px">
    <el-form-item label="用户名" prop="username">
      <el-input placeholder="请输入用户名" v-model="form.username" />
    </el-form-item>
    <el-form-item label="UserId" prop="userId">
      <el-input placeholder="请输入钉钉UserId" v-model="form.userId" />
    </el-form-item>
  </el-form>
  <template #footer>
    <span class="dialog-footer">
      <el-button @click="close(false)">取消</el-button>
      <el-button type="primary" @click="close(true)">确定</el-button>
    </span>
  </template>
</el-dialog>
</template>

<script>
// 权限管理 权限管理 新增用户
import { useStore } from 'vuex';
import { reactive, ref, toRefs } from 'vue';
import { addUserById } from "api/apis.js";
import { ElMessage, FormInstance } from 'element-plus';
export default {
  setup(prop, ctx) {
    const store = useStore();
    let moment = require("moment");
    const state = reactive({
      ruleFormRef: ref(FormInstance),
      visible: true,
      loading: false,
      form: {
        username: null,
        userId: null
      },
      rules: {
        username: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        userId: [{ required: true, message: "请输入用户userId", trigger: "blur" }]
      }
    });

    const close = (type) => {
      if (type) {
        state.ruleFormRef.validate(valid => {
          if (valid) {
            state.loading = true;
            addUserById({
              username: state.form.username,
              userid: state.form.userId,
              txt_creator: store.getters.userInfo.name || "系统用户",
              dt_create: moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
            }).then(response => {
              if (response.code == 200) {
                ElMessage.success("添加成功");
                state.visible = false;
                ctx.emit("close", type);
              } else {
                ElMessage.error(response.msg);
              }
            }).catch(e => {
              console.error(e);
            }).finally(() => {
              state.loading = false;
            })
          }
        })
      } else {
        state.visible = false;
        ctx.emit("close", type);
      }
    };
    return {
      ...toRefs(state),
      close
    }
  }
}
</script>

<style lang="scss">
.authority-add-dialog {
  width: 500px;
  height: 300px;

  .el-dialog__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .el-form {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
