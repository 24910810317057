<template>
<div id="page" class="authority-manage-index">
  <el-button class="ami-btn" type="primary" @click="dialog.addShow=true">添加用户</el-button>
  <el-table class="ami-table" :data="table.tableData" v-loading="table.tableLoading">
    <el-table-column prop="txt_dingding_name" label="姓名" align="center" />
    <el-table-column prop="txt_unionid" label="钉钉UnionId" align="center" />
    <el-table-column prop="dt_create" label="创建时间" align="center" />
    <el-table-column label="操作" align="center" width="150" class-name="table-option">
      <template #default="scope">
        <div class="table-icon" @click="handleEdit(scope.row)">
          <el-icon>
            <EditPen />
          </el-icon>
          编辑
        </div>
        <div class="table-icon" @click="handleDelete(scope.row)">
          <el-icon>
            <Delete />
          </el-icon>删除
        </div>
      </template>
    </el-table-column>
  </el-table>
  <pagination :total="page.total" @sizeChange="(e) => {page.limit=e;getList()}" @pageChange="(e) => {page.page=e;getList()}" />
  <auth-add v-if="dialog.addShow" @close="(e) => {addDialogEvent(e);}" />
  <auth-edit v-if="dialog.editShow" :editData="dialog.editData" @close="(e) => {editDialogEvent(e);}" />
</div>
</template>

<script>
// 权限管理 - 权限管理 首页
import { useStore } from 'vuex';
import { Pagination } from "components";
import { nextTick, onMounted, reactive, toRefs } from 'vue';
import { EditPen, Delete } from "@element-plus/icons-vue";
import AuthAdd from './comps/authAdd.vue';
import AuthEdit from './comps/authEdit.vue';
import { getUsers, deleteUserAndAuth } from "api/apis.js";
import { ElMessage, ElMessageBox } from 'element-plus';
export default {
  components: { Pagination, EditPen, Delete, AuthAdd, AuthEdit },
  setup() {
    const store = useStore();
    let moment = require("moment");
    const state = reactive({
      table: { tableData: [], tableLoading: false },
      page: { total: 0, limit: 10, page: 1 },
      dialog: { addShow: false, editShow: false, editData: null }
    });

    onMounted(() => {
      getList();
    });

    // 编辑用户
    const handleEdit = (item) => {
      state.dialog.editData = item;
      nextTick(() => {
        state.dialog.editShow = true;
      })
    };

    // 获取列表数据
    const getList = () => {
      state.table.tableLoading = true;
      getUsers(state.page).then(response => {
        if (response.code == 200) {
          state.table.tableData = response.data.userlist;
          state.page.total = response.data.count;
        } else {
          ElMessage.error(response.msg);
          state.table.tableData = [];
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.table.tableLoading = false;
      })
    };

    // 删除用户
    const handleDelete = (e) => {
      ElMessageBox.confirm(
        `确定要删除用户【${e.txt_dingding_name}】吗`,
        '提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }
      ).then(() => {
        state.table.tableLoading = true;
        deleteUserAndAuth({
          t_dingding_user_id: e.t_dingding_user_id,
          txt_updater: store.getters.userInfo.name || "系统用户",
          dt_update: moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
        }).then(response => {
          if (response.code == 200) {
            ElMessage.success("删除成功");
            getList();
          } else {
            ElMessage.error(response.msg);
          }
        }).catch(e => {
          console.error(e);
        }).finally(() => {
          state.table.tableLoading = false;
        })
      })
    };

    // 新增 dialog 事件
    const addDialogEvent = (e) => {
      state.dialog.addShow = e;
      if (e) {
        getList();
      }
    };
    // 编辑 dialog 事件
    const editDialogEvent = (e) => {
      state.dialog.editShow = e;
      if (e) {
        getList();
      }
    };

    return {
      ...toRefs(state),
      getList,
      addDialogEvent,
      handleEdit,
      editDialogEvent,
      handleDelete
    }
  }
}
</script>

<style lang="scss" scoped>
.authority-manage-index {
  .ami-btn {
    float: right;
    margin-bottom: 6px;
  }

  .ami-table {
    height: calc(100% - 80px);
  }
}
</style>
