<template>
<el-dialog v-model="visible" :title="title" :append-to-body="true" custom-class="authority-edit-dialog" :close-on-click-modal="false">
  <el-table id="aed-table" :data="table.tableData" v-loading="table.tableLoading">
    <el-table-column label="页面名称" prop="txt_comments" align="center" />
    <el-table-column label="可视权限" prop="canSee" align="center">
      <template #default="scope">
        <el-switch v-model="scope.row.is_visual" @change="handleSwitch($event, scope.row)" />
      </template>
    </el-table-column>
    <el-table-column label="操作权限" prop="int_authorisation_level" align="center">
      <template #default="scope">
        <el-switch v-model="scope.row.int_authorisation_level" :disabled="!scope.row.is_visual" />
      </template>
    </el-table-column>
    <el-table-column label="地区权限" prop="txt_area_codes" align="center" width="200">
      <template #default="scope">
        <el-select class="my-select" ref="regionRef" :disabled="!scope.row.is_visual" @change="handleChecked($event, scope.row)" v-model="scope.row.txt_area_codes" multiple collapse-tags collapse-tags-tooltip>
          <el-option v-for="(item, index) in scope.row.regionOption" :disabled="item.disabled" :key="index" :label="item.txt_area_name" :value="item.txt_code" />
        </el-select>
      </template>
    </el-table-column>
    <el-table-column label="用户权限" prop="t_user_agent_id" align="center">
      <template #default="scope">
        <el-select class="my-select" ref="userRef" :disabled="!scope.row.is_visual" v-model="scope.row.t_user_agent_id">
          <el-option v-for="(item, index) in options.userOption" :key="index" :label="item.txt_realname" :value="item.t_agent_id" />
        </el-select>
      </template>
    </el-table-column>
  </el-table>
  <template #footer>
    <span class="dialog-footer">
      <el-button @click="close(false)">取消编辑</el-button>
      <el-button type="primary" @click="close(true)">保存权限</el-button>
    </span>
  </template>
</el-dialog>
</template>

<script>
// 权限管理 编辑权限
import { useStore } from 'vuex';
import { onMounted, reactive, toRefs, ref } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import { getUserAuthInfo, getEveryLevelInfo, getAuthRegion, updateUserAuthInfo } from "api/apis.js";
export default {
  props: { editData: { type: Object, default: function () { return {} } } },
  setup(prop, ctx) {
    const store = useStore();
    let moment = require("moment");
    const state = reactive({
      regionRef: ref(null),
      userRef: ref(null),
      visible: true,
      options: { regionOption: [], userOption: [] },
      param: {},
      title: "权限维护",
      table: { tableData: [], tableLoading: false }
    })

    onMounted(() => {
      state.param = prop.editData;
      state.title = "【" + prop.editData.txt_dingding_name + "】" + state.title
      getPorPartners();
      getAreaOption();
      // window.addEventListener("scroll", scrollChange, true);
    });

    // 实时滚动条高度
    const scrollChange = () => {
      const SELECTWRAP_BODY = document.body; // body
      const SELECTWRAP_DOWNALL = document.querySelectorAll('.el-select-dropdown'); // select 下拉框
      SELECTWRAP_BODY.click();
      SELECTWRAP_DOWNALL.forEach((item) => {
        item.style.display = 'none'
      })
      // state.userRef.blur();
      // if (regionRef.value != null) {
      //   regionRef.value.close();
      // }
      // let dialog = document.getElementsByClassName("authority-edit-dialog");
      // let event = document.createEvent("MouseEvents");
      // event.initEvent('click', true, true);
      // document.getElementById("aed-table").click()
      // $(".el-popper").hide();
      // $("#aed-table").trigger("click");
      // console.log($(".my-select")) 
    };

    // 获取地区信息
    const getAreaOption = () => {
      getAuthRegion({ parea_code: 0 }).then(response => {
        if (response.code == 200) {
          response.data.unshift({ txt_area_name: "全国", txt_code: "0", disabled: false });
          state.options.regionOption = response.data.map(t => {
            return {
              txt_code: t.txt_code,
              txt_area_name: t.txt_area_name,
              disabled: false
            }
          });
          getData();
        }
      }).catch(e => {
        console.error(e);
      })
    };

    // 获取所有省代
    const getPorPartners = () => {
      getEveryLevelInfo({
        int_agent_level: 1,
        limit: 999
      }).then(response => {
        if (response.code == 200) {
          state.options.userOption = response.data.data;
        } else {
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      })
    };

    // 获取权限
    const getData = () => {
      state.table.tableLoading = true;
      getUserAuthInfo({
        t_dingding_user_id: state.param.t_dingding_user_id
      }).then(response => {
        if (response.code == 200) {
          // 格式化数据
          let result = [];
          response.data.node.forEach(item => {
            let t_dingding_page_id = item.t_dingding_page_id,
              txt_page_id = item.txt_page_id,
              txt_comments = item.txt_comments,
              txt_router = item.txt_router,
              int_authorisation_level = false,
              t_user_agent_id = null,
              txt_area_codes = [],
              is_visual = false,
              regionOption = JSON.parse(JSON.stringify(state.options.regionOption));
            if ((response.data.userNode || []).length != 0) {
              let index = response.data.userNode.findIndex(t => t.t_dingding_page_id == t_dingding_page_id);
              if (index != -1) {
                int_authorisation_level = response.data.userNode[index].int_authorisation_level == 1 ? true : false;
                t_user_agent_id = response.data.userNode[index].t_user_agent_id;
                txt_area_codes = response.data.userNode[index].txt_area_codes == null ? null : response.data.userNode[index].txt_area_codes.split(",");
                is_visual = true;
              }
            }
            // 如果已经有了全国 权限，则禁用其他地区选项
            if ((txt_area_codes || []).findIndex(t => t == "0") != -1) {
              regionOption.forEach(item => {
                if (item.txt_code != "0") {
                  item.disabled = true;
                }
              })
            }
            result.push({ t_dingding_page_id, regionOption, txt_page_id, txt_comments, txt_router, int_authorisation_level, t_user_agent_id, txt_area_codes, is_visual })
          });
          state.table.tableData = result;
        } else {
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.table.tableLoading = false;
      })
    };

    // 全国 选择器 事件
    const handleChecked = (event, item) => {
      for (let i = 0; i < item.regionOption.length; i++) {
        let key = item.regionOption[i];
        // 选项中包含 “全国”
        if (event.findIndex(t => t == "0") != -1) {
          if (key.txt_code != "0") {
            key.disabled = true;
          }
          // “全国” 与其他地区互斥 存在
          item.txt_area_codes = ["0"]
        } else {
          key.disabled = false;
        }
      }
    };

    // 可使权限 switch 事件
    const handleSwitch = (event, item) => {
      // true => false 时，其他三个重置
      if (event == false) {
        item.int_authorisation_level = false;
        item.txt_area_codes = [];
        item.t_user_agent_id = null;
      }
    };

    // 按钮事件
    const close = (type) => {
      if (!type) {
        ElMessageBox.confirm(
          `是否取消保存当前更改？`,
          '提示', {
            distinguishCancelAndClose: true,
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }
        ).then(() => {
          state.visible = false;
          ctx.emit("close", type);
        })
      } else {
        // 格式化数据，调用保存接口
        submitData();
      }
    };

    // 保存数据
    const submitData = () => {
      state.table.tableLoading = true;
      // 格式化数据
      let param = {
        t_dingding_user_id: state.param.t_dingding_user_id,
        txt_updater: store.getters.userInfo.name || "系统用户",
        dt_update: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        userNodes: state.table.tableData.map(t => {
          return {
            t_dingding_user_id: state.param.t_dingding_user_id,
            t_dingding_page_id: t.t_dingding_page_id,
            int_authorisation_level: t.int_authorisation_level ? 1 : null,
            is_visual: t.is_visual ? 1 : null,
            t_user_agent_id: t.t_user_agent_id,
            txt_area_codes: t.txt_area_codes.map(e => e).join(",")
          }
        })
      }

      updateUserAuthInfo(JSON.stringify(param)).then(response => {
        if (response.code == 200) {
          ElMessage.success("编辑权限成功");
          state.visible = false;
          ctx.emit("close", true);
        } else {
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.table.tableLoading = false;
      })
    };

    return {
      ...toRefs(state),
      getData,
      getPorPartners,
      getAreaOption,
      handleChecked,
      handleSwitch,
      close,
      scrollChange,
    }
  }
}
</script>

<style lang="scss">
.authority-edit-dialog {
  width: 800px;

  .el-dialog__body {
    .el-table {
      height: 100%;
    }
  }
}
</style>
